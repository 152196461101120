<template>
  <div id="case">
    <!-- 精选案例 -->
    <!-- <div class="banner">
      <h1>精选案例</h1>
    </div> -->
    <Title :title="title"></Title>
    <!-- <SelectedCase :caseList="list"></SelectedCase> -->
    <SelectedCaseNew :caseList="list" class="cases"></SelectedCaseNew>
    <div class="line"></div>
  </div>
</template>

<script>
import Title from "@/components/Title";
import { sid, getAllCourse } from "@/utils/api.js";

export default {
  name: "Case",
  components: {
    // SelectedCase: () => import("@/components/SelectedCase"),
    SelectedCaseNew: () => import("@/components/SelectedCaseNew"),
    Title,
  },
  data() {
    return {
      list: [],
      title: "精选案例",
    };
  },
  methods: {
    getCase() {
      this.$http
        .jsonp(getAllCourse + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno === "0") {
            // console.log(res.data);
            this.list = res.data;
          }
        });
    },
  },
  created() {
    this.getCase();
  },
};
</script>

<style lang="scss" scoped>
a,
a:hover,
a:visited {
  color: #000000;
  text-decoration: none;
}

#case {
  text-align: left;
  .cases {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .selected-case {
    border-color: transparent;
  }
  .line {
    width: 100%;
    max-width: 1200px;
    height: 1px;
    margin: 0 auto;
    background: #f1f4f7;
  }
  .banner {
    width: 100%;
    height: 300px;
    background-color: #fafbfc;
    h1 {
      letter-spacing: 3px;
      line-height: 300px;
      text-align: center;
      font-family: PingFangSCBold;
      font-size: 50px;
      font-weight: bold;
      color: #2c3e50;
    }
  }
  .case_con {
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 60px;
    border-bottom: 1px solid #f1f4f7;
    ul {
      width: 1200px;
      margin: 0 auto;
      margin-top: 37.5px;
      li {
        width: 33.3%;
        // height: 243px;
        float: left;
        padding: 12.5px;
        box-sizing: border-box;
        .a_con {
          width: 100%;
          height: 100%;
          padding: 22px 22px 21px 20px;
          border: 1px solid #d7d7d7;
          background-color: #ffffff;
          .case_title {
            height: 50px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #003177;
            line-height: 25px;
          }
          hr {
            border: 0.5px solid #d7d7d7;
            margin: 13px auto;
          }
          div {
            padding-left: 9px;
            div {
              height: 13px;
              margin-top: 25px;
              padding-left: 0;
              img {
                width: auto;
                height: 100%;
              }
            }
            .case_name {
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #484848;
              line-height: 28px;
              margin-top: 8px;
            }
            .case_company,
            .case_position {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3e3a39;
              line-height: 18px;
            }
          }
        }
        a > div:hover {
          box-shadow: 0px 0px 8px 0px rgba(2, 72, 111, 0.1);
          cursor: url("../assets/img/mouse2.png"), auto !important;
        }
      }
    }
  }
}

.clear_float {
  clear: both;
  float: none !important;
  width: 0 !important;
  height: 0 !important;
  border: 0 !important;
}
@media (max-width: 1023px) {
  #case {
    margin-top: 0;
    .line {
      width: 100vw;
    }
  }
}
</style>